import React, { useRef } from "react";
import "animate.css/animate.min.css";

import "./assets/stylesheets/base.scss";
import {
  Header,
  Intro,
  Headshot,
  Projects,
  Experience,
  Contact,
  About,
  Footer,
  Mom
} from "./components";

function App() {
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const experienceRef = useRef(null);
  const contactRef = useRef(null);

  if (window.location.pathname === '/mom') return <Mom />

  return (
    <>
      <Header allRefs={{ aboutRef, projectsRef, experienceRef, contactRef }} />
      <main>
        <Intro />
        <section className="section-scroll">
          <Headshot />
          <About ref={aboutRef} />
          <Projects ref={projectsRef} />
          <Experience ref={experienceRef} />
          <Contact ref={contactRef} />
          <Footer />
        </section>
      </main>
    </>
  );
}

export default App;
