import React, { useState } from "react";
import styles from "./styles.module.scss";
import { experience } from "../../config";

// first arg passed to callback is props
const Experience = React.forwardRef((_, ref) => {
  const [currentTab, setCurrentTab] = useState(0);

  const displayDetails = (details) =>
    details.map((point, i) => <li key={i}>{point}</li>);

  const renderExperience = experience.map(
    ({ title, company, start, end, details, url }, i) => (
      <div
        key={i}
        hidden={i !== currentTab}
        className={styles.experienceContent}
      >
        <h3>
          <span>{title}</span>
          <a
            href={url}
            aria-label={company}
            target="_blank"
            rel="noreferrer"
            className={styles.company}
          >
            &nbsp;@&nbsp;
            {company}
          </a>
        </h3>
        <p>
          {start} - {end}
        </p>
        {/* <ul> */}
        {displayDetails(details)}
        {/* </ul> */}
      </div>
    )
  );

  const renderCompanies = experience.map(({ company }, i) => (
    <li
      key={i}
      onClick={() => setCurrentTab(i)}
      className={currentTab === i ? styles.selected : undefined}
    >
      <button className={styles.tabButton}>{company}</button>
    </li>
  ));

  return (
    <section className={styles.section} ref={ref} id="experience">
      <h2 className={styles.title}>Places I've Worked</h2>
      <div className={styles.experienceContainer}>
        <ol className={styles.companiesTabs}>{renderCompanies}</ol>
        {renderExperience}
      </div>
    </section>
  );
});

export default Experience;
