import styles from "./styles.module.scss"

const content = {
    heading: "Merry Christmas Mom!",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQciJKi6gZ_mj_YSbVv3Eo_SRzf1k7ugT8fnQ&usqp=CAU",
    imageAlt: "silkie in a christmas tree",
    cardSaying: "To the best mom ever – wishing you joy, love, and happiness this Christmas season and always. You deserve it.",
    firstParagraph: "Thank you for all you have done and continue to do for the family!",
    secondParagraph: "You inspire me everyday with how much you do and show all of us how to be a hard worker.",
    thirdParagraph: "I love you so much!",
    fourthParagraph: "Hope you have a wonderful day!",
    sign: "❤️ Emiley",
    present: "https://target.scene7.com/is/image/Target/GUEST_a76db6e6-59c1-4c35-8f8b-761e3132c42e?wid=1200&hei=1200&qlt=80&fmt=webp",
    presentAlt: "Fireside By Dearfoams Women's Perth Genuine Shearling Foldover Boot in color Black"
}

function Mom() {
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{content.heading}</h1>
            <img src={content.image} alt={content.imageAlt}/>
            <h2 className={ styles.saying }>{content.cardSaying}</h2>
            <p className={styles.paragraph}>{content.firstParagraph}</p>
            <p className={styles.paragraph}>{content.secondParagraph}</p>
            <p className={styles.paragraph}><b>{content.thirdParagraph}</b></p>
            <p className={styles.paragraph}>{content.fourthParagraph}</p>
            <h3>{content.sign}</h3>
            <div className={styles.spacing}></div>
            <img src={content.present} alt={content.presentAlt} className={styles.presentImage}/>
            <p>They're in the mail expected delivery Jan. 3</p>
        </div>
    )
}

export default Mom;