import React from "react";
import styles from "./styles.module.scss";
import { tech } from "../../config";
import fiddle from "../../assets/images/fiddle.jpg";
import yosemite from "../../assets/images/yosemite-falls.webp";
import coffee from "../../assets/images/coffee-desk.jpg";
// coffee image @brigittetohm

const About = React.forwardRef((_, ref) => {
  const displayTech = tech && tech.map((t, i) => <li key={i}>{t}</li>);
  return (
    <section className={styles.section} ref={ref} id="about">
      <h2 className={styles.title}>About Me</h2>
      <p className={styles.content}>
        Hi! I'm Emiley and I enjoy building things for the web. My interest in
        coding started in college when I was asked to program a robot to do a
        little dance. From there, I started taking courses on Udemy and
        eventually enrolled in Flatiron School. Flash-forward to today, I'm an
        Instructor at Flatiron School teaching full stack web development.
      </p>
      <p className={styles.content}>
        When I'm not at my computer, you can find me playing golf, caring for my
        plants, solving puzzles, building something, or adventuring in the
        mountains. Of course always with a cup of coffee in hand.
      </p>
      <p className={styles.content}>
        Some technologies I've been working with lately:
      </p>
      <ul id={styles.techList}>{displayTech}</ul>
      <div className={styles.imagesContainer}>
        <span className={styles.imageWrapper}>
          <img src={fiddle} alt="plants" className={styles.aboutImage} />
        </span>
        <span className={styles.imageWrapper}>
          <img src={yosemite} alt="nature" className={styles.aboutImage} />
        </span>
        <span className={styles.imageWrapper}>
          <img src={coffee} alt="coffee" className={styles.aboutImage} />
        </span>
      </div>
    </section>
  );
});

export default About;
