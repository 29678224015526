import React, { useState } from "react";
import Burger from "@animated-burgers/burger-squeeze";
import "@animated-burgers/burger-squeeze/dist/styles.css";

import "./header.css";
import Nav from "./Nav";

const Header = ({ allRefs }) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <header className="header">
      <div className={navOpen ? "nav-container open" : "nav-container"}>
        <Burger isOpen={navOpen} onClick={() => setNavOpen(!navOpen)} />

        <Nav isOpen={navOpen} setNavOpen={setNavOpen} allRefs={allRefs} />
      </div>
    </header>
  );
};

export default Header;
