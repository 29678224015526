import React from "react";
import {
  IconGithub,
  IconInstagram,
  IconLinkedIn,
  IconEmail,
  IconExternalLink,
} from ".";

const Icon = ({ name }) => {
  switch (name) {
    case "Github":
      return <IconGithub />;
    case "LinkedIn":
      return <IconLinkedIn />;
    case "Instagram":
      return <IconInstagram />;
    case "Email":
      return <IconEmail />;
    case "External":
      return <IconExternalLink />;
    default:
      return;
  }
};

export default Icon;
