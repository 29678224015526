

import styles from "./styles.module.scss";
import fiddle from "../../assets/images/fiddle.jpg";

import Icon from "../icons/Icon";

const ProjectCard = ({ title, description, links, image, techUsed }) => {
  const imageUrl = `${process.env.PUBLIC_URL}/${image}`
  const displayIcons = links.map(({ name, url }, i) => (
    <li key={i}>
      <a href={url} aria-label={name} target="_blank" rel="noreferrer">
        <Icon name={name} />
      </a>
    </li>
  ));

  return (
    <article className={styles.projectCard}>
      <div className={styles.projectInfo}>
        <div className={styles.text}>
          <h3 className={styles.projectTitle}>{title}</h3>
          <p className={styles.projectDescription}>{description}</p>
          <ul className={styles.projectTechUsed}>
            {techUsed.map((tech, i) => (
              <li key={i}>{tech}</li>
            ))}
          </ul>
        </div>
        <ul className={styles.icons}>{displayIcons}</ul>
      </div>
      <span className={styles.imageWrapper}>
        <img src={image ? imageUrl : fiddle} alt={title} className={styles.projectImage} />
      </span>
    </article>
  );
};

export default ProjectCard;
