import React from "react";
import styles from "./styles.module.scss";
import { projects } from "../../config";

import ProjectCard from "./ProjectCard";

const Projects = React.forwardRef((_, ref) => {
  const displayProjects = projects.map((project, i) => (
    <ProjectCard key={i} {...project} />
  ));
  return (
    <div className={styles.section} ref={ref} id="projects">
      <h2 className={styles.title}>Some Things I've Built</h2>
      {displayProjects}
    </div>
  );
});

export default Projects;
