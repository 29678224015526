import React from "react";
import "./header.css";

const Nav = ({
  isOpen,
  setNavOpen,
  allRefs: { aboutRef, projectsRef, experienceRef, contactRef },
}) => {
  const handleClick = (ref) => {
    isOpen && setNavOpen(false);
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ol className={isOpen ? "nav hamburger-open " : "nav hamburger-closed"}>
      <li className={"link"} onClick={() => handleClick(aboutRef)}>
        About
      </li>
      <li className="link" onClick={() => handleClick(projectsRef)}>
        Projects
      </li>
      <li className="link" onClick={() => handleClick(experienceRef)}>
        Experience
      </li>
      <li className="link" onClick={() => handleClick(contactRef)}>
        Contact
      </li>
      <li onClick={() => handleClick(aboutRef)} id="resume">
        <a className="link" href="Emiley-resume.pdf" target="_blank">
          Resume
        </a>
      </li>
    </ol>
  );
};

export default Nav;
