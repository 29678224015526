import styles from "./styles.module.scss";
import { socialMedia } from "../../config";
import Icon from "../icons/Icon";

const Intro = () => {
  const displayIcons =
    socialMedia &&
    socialMedia.map(({ name, url }, i) => {
      return (
        <li key={i}>
          <a href={url} aria-label={name} target="_blank" rel="noreferrer">
            <Icon name={name} />
          </a>
        </li>
      );
    });

  return (
    <div className={styles.introContainer}>
      <section className={styles.intro}>
        <h1 className={styles.welcome}>
          <span className={styles.mediumFont}>Hi, my name is</span>{" "}
          <span className={styles.name}>Emiley Palmquist.</span>
        </h1>
        <p className={styles.smallFont}>
          I'm a software engineer based out of{" "}
          <span className={styles.location}> San Jose, CA.</span>
        </p>
      </section>
      <ul className={styles.icons}>{displayIcons}</ul>
    </div>
  );
};

export default Intro;
