import React from "react";
import styles from "./styles.module.scss";

const Footer = () => (
  <footer id={styles.footer}>
    <p>Designed and Built by Emiley Palmquist.</p>
  </footer>
);

export default Footer;
