import React from "react";
import styles from "./styles.module.scss";

const Contact = React.forwardRef((_, ref) => {
  return (
    <div className={styles.section} ref={ref} id="contact">
      <h2 className={styles.title}>Let's Get in Touch</h2>
      <p className={styles.contactContent}>
        My inbox is always open! Whether you have a question or just want to say
        hi, I'll try my best to get back to you as soon as I can.
      </p>
      <a
        className={styles.sayHiButton}
        href="mailto:emiley.palmquist@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        Say Hi
      </a>
    </div>
  );
});
export default Contact;
