module.exports = {
  email: "emiley.palmquist@gmail.com",
  resume: "",
  socialMedia: [
    { name: "Github", url: "https://github.com/emileypalmquist" },
    { name: "LinkedIn", url: "https://www.linkedin.com/in/emiley-palmquist/" },
    { name: "Instagram", url: "https://www.instagram.com/emiley_12" },
    { name: "Email", url: "mailto:emiley.palmquist@gmail.com" },
  ],
  tech: [
    "React",
    "JavaScript",
    "React Native",
    "Ruby on Rails",
    "Python",
    "Flask",
    "Redux",
    "CSS/SCSS",
  ],
  experience: [
    {
      title: "Instructor, Software Engineering",
      company: "Flatiron School",
      start: "June 2021",
      end: "Present",
      details: [
        "Debug, mentor, plan, and teach full-stack concepts and best practices in JavaScript and Python",
        "Oversee student projects from initial design to deployment as technical mentor and project manager; conduct weekly code reviews",
        "Plan and lead introduction to web development events for prospective students",
      ],
      url: "https://flatironschool.com/",
    },
    {
      title: "Frontend Software Engineer",
      company: "KIWI",
      start: "February 2022",
      end: "September 2022",
      details: [
        "Planned, built, and implemented the user interface from Figma design with Next.js",
        "Collaborated with Senior Backend Engineer and Designer",
        "Wrote functional requirement documents and guides",
        "Worked directly with the client to define and clarify requirements",
        "Identified UI problems and bugs and devised elegant solutions"
      ],
      url: "https://app.yourkiwis.com/",
    },
    {
      title: "Coach, Software Engineering",
      company: "Flatiron School",
      start: "January 2020",
      end: "June 2021",
      details: [
        "Debugged, mentored, and taught full-stack concepts and best practices in JavaScript and Ruby to over 200 students",
        "Oversaw more than 150 student projects from initial design to deployment as technical mentor and project manager; conducted weekly code reviews",
        "Administered technical interviews to prospective students in JavaScript",
      ],
      url: "https://flatironschool.com/campuses/san-francisco",
    },
    // {
    //   title: "Barista",
    //   company: "Starbucks",
    //   start: "January 2019",
    //   end: "January 2020",
    //   details: [
    //     "Developed professional relationships with clientele to ensure and provide quality performance",
    //     "Applied knowledge of drink formulas and training skills to provide quality coffee and beverages every shift",
    //     "Communicated with coworkers to maintain a functional work environment and assist one another with knowledge of Starbucks’ guidelines",
    //   ],
    //   url: "https://www.starbucks.com/",
    // },
    {
      title: "Certified Nursing Assistant",
      company: "Covenant Care",
      start: "June 2016",
      end: "May 2017",
      details: [
        "Managed and documented patient care for a daily caseload of 15-20 elderly patients with mental disorders to ensure patient’s well-being",
        "Conducted vital sign tests for Registered Nurses and Medical Doctors",
        "Updated managers daily about patient’s status to ensure no declines in patient health",
        "Built strong relationships with coworkers and patients",
      ],
      url: "https://www.covenantcare.com/",
    },
  ],
  projects: [
    {
      title: "Social Garden",
      links: [
        { name: "External", url: "https://social-garden-8706d.web.app/" },
        {
          name: "Github",
          url: "https://github.com/emileypalmquist/plant-social-client",
        },
        {
          name: "Github",
          url: "https://github.com/emileypalmquist/plant-social-server",
        },
      ],
      description:
        "A place to share your plants and plant care tips. You can interact with other plants by liking, commenting, and upvoting care notes you found helpful.",

      image: "plant-social.png",
      techUsed: ["React", "Ruby on Rails", "Postgresql", "AWS S3"],
    },
    // {
    //   title: "Golf Stats Caddy",
    //   links: [
    //     { name: "External", url: "url" },
    //     { name: "Github", url: "url" },
    //     { name: "Github", url: "url" },
    //   ],
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio. ",
    //   image: "",
    //   techUsed: ["React Native", "Ruby on Rails", "Postgresql"],
    // },
    // {
    //   title: "Group Messenger",
    //   links: [
    //     { name: "External", url: "url" },
    //     { name: "Github", url: "url" },
    //     { name: "Github", url: "url" },
    //   ],
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio. ",
    //   image: "",
    //   techUsed: ["React", "Ruby on Rails", "Postgresql"],
    // },
  ],
};
